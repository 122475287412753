<template>
  <div v-if="loading_dashboard">
    <Index />
  </div>
  <div v-else id="dashboard">
    <v-app>
      <v-app-bar  app color="#1565C0" dark elevation='0'    clipped-left>
        <v-app-bar-nav-icon 
          @click.stop="menulateral = !menulateral"
        >
        </v-app-bar-nav-icon>
        <!-- Ubicacion logo datari -->
        <v-toolbar-title>
          <div class="d-flex align-center">
            <v-img
              alt="labict logo"
              class="shrink "
              contain
              src="@/media/logo_blanco.png"
              transition="scale-transition"
              width="140"
              height ="35"
            />
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col xl=2>
        <v-autocomplete
            dense
            v-model="regionDetail"
            :items="regiones"
            cache-items
            flat
            hide-no-data
            hide-details    
            label="Región:"
            solo-inverted
            item-text="nombre"
            item-value="id"
            v-on:change="cambiarRegion"
          ></v-autocomplete> 
        </v-col>
        <v-col xl=2>
          <v-autocomplete
            dense
            v-model="comunaDetail"
            :items="comunas"
            
            flat
            hide-no-data
            hide-details
            label="Comunas"
            solo-inverted
            item-text="nombre"
            item-value="id"
            v-on:change="cambiarComuna"
            :disabled="no_comunas"
          ></v-autocomplete>
        </v-col>
        <v-spacer/>
        <v-icon>mdi-account</v-icon>
        <span class="ml-1 mr-2">{{user}}</span>
        <v-btn
          @click="logout"
          v-if="isLoggedIn"
          text
        > 
          <v-icon>mdi-power-cycle</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer 
        v-model="menulateral" 
        floating
        app
        :permanent="menulateral"
        clipped
        width="275"
        class="blue darken-1"
        dark
        >
        <v-list nav>
          <v-list-item class="px-1" color="blue darken-4" link :to="{ path: '/'}" >
            <v-list-item-avatar>
                <v-icon style="color:white">mdi-home-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content  class="font-weight-medium"  style="color:white">
                Home
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-1"  >
              <v-list-item-avatar>
                  <v-icon>mdi-apps</v-icon>
              </v-list-item-avatar>
              <v-list-item-content  class="font-weight-medium" >
                  Categorias
              </v-list-item-content>
          </v-list-item>
          <template v-for="categoria in categorias"  >
            <v-list-group :key="categoria.nombre"  >
              <template v-slot:activator >
                <v-list-item style="margin-left:-10px"  color="blue darken-4"  >
                  <v-list-item-avatar>
                    <v-icon style="color:white">{{categoria.icon}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium" style="color:white;">{{categoria.nombre}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item dense v-for="child in categoria.fuenteDato" color="blue darken-4" class="px-2 " :key="child.nombre" link :to="{path: '/categoria-'+categoria.id+'/'+child.url}">
                  <v-icon style="color:white">mdi-chart-box</v-icon>
                <v-list-item-content>
                  <v-list-item-title dark class="font-weight-medium ml-2" style="color:white">    {{child.nombre}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item >
              <v-divider class="mb-2" width="100%"/>
            </v-list-group >
          </template>
          <v-list-item class="px-1" color="blue darken-4" link :to="{ path: '/admin'}"  v-if="is_staff === 'true'">
            <v-list-item-avatar>
              <v-icon style="color:white">mdi-account-cog-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content  class="font-weight-medium"  style="color:white">
              Admin
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-navigation-drawer>
        <v-main style="background-color:#D5D8DC" >
            <v-container fluid transition="slide-x-transition">
                  <router-view 
                    :region="region" 
                    :data_region="regionDetail"
                    :data_comuna="comunaDetail"
                    :comuna="comuna" 
                    v-on:cambiarComuna="cambiarComuna"
                    :menulateral="menulateral" 
                    :no_comunas="no_comunas"
                    @update="updateDataComuna"
                    :regiones="regiones"

                  >
                    
                  </router-view>

             
            </v-container>
            
        </v-main>
<!--         <loadingData :overlay="overlay" style="z-index: 9999"/>
 -->
        <v-footer dark app padless>
          <span class="white--text ml-2"><small>LAB ICT &copy; {{ new Date().getFullYear() }}</small> </span>
        </v-footer>
    </v-app>
  </div>
</template>



<script>
const API_URL = 'https://api.datari.net/datari/data/'
import Index from './indexView.vue';


import axios from 'axios';
  export default {
    name: 'Dashboard',
    components: {
      Index
    },
    data: () => ({
      menulateral: true,
      toggleMini: false,
      categorias: [],
      regiones: [],
      regionDetail: [],
      //true
      loading_dashboard:true,
      items: [],
      search: null,
      comunas: [],
      comunaDetail: null,
      region: 1,
      comuna: 0,
      overlay: true,
      no_comunas: true,
      fuentes_comunas : ['Exportaciones','Importaciones','Transporte Territorial'],

    }),
    created() {
        this.create_data_regiones();
        this.create_data_region(this.region);
        this.create_data_comuna(this.comuna);
        this.create_data_categorias();
        this.sleep(6000).then(() => {          
          this.loading_dashboard = false;
        });
    },
    computed : {
      isLoggedIn: function(){ return this.$store.getters.isLoggedIn},

      user: function(){ 
        return localStorage.getItem('user') 
      },

      
      is_staff: function(){
        let x = localStorage.getItem('is_staff');
        return x
      },

      mini() {
          return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
      },

      item(){
        
        return this.region,this.comuna
      },

      filtrarItems(){
        var categorias = this.categorias;
        console.log(categorias);
        return categorias
      }
    }, 

    watch: {
      search(val) {
        val && val !== this.regionDetail && this.querySelections(val)
      },
      

    },

    methods: {

      create_data_regiones(){
        axios.get(API_URL+"regiones/")
        .then(response => {
          let data = response.data.results;
          this.regiones = data.filter(region =>  region.id != 17 && region.id != 18 );  
        });
      },

      create_data_categorias(){
        axios.get(API_URL + "categorias/")
        .then(response => {
            this.categorias = response.data.results
        });

      },

      create_data_comuna(comuna){
        if(comuna != 0){
          axios.get(API_URL+"comunas/"+comuna+'/')
          .then(response => this.comunaDetail = response.data);
        }else{
          this.comunaDetail = {id: '0',nombre: 'Comunas'}
        }
      },

      create_data_region(region){

        axios.get(API_URL+"regiones/"+region+'/')
        .then(response => this.regionDetail = response.data);
 
        axios.get(API_URL + "comunas/?region="+region)
        .then(response => {
          this.comunas = response.data.results;
          var valor_inicial = {id: '0',nombre: 'Comunas'};
          this.comunas.push(valor_inicial);
          this.ordenarLista(this.comunas);
        });

      },


      updateDataComuna(valor,id_comuna){
        this.no_comunas = valor;
        this.comunaDetail = id_comuna;
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      logout: function () {
        
        this.menulateral = false
        
        this.$store.dispatch('logout')
        .then(() => {
          this.loading_dashboard = true;
          this.sleep(3000).then(() =>{
            this.$router.push('/login')
          });
          
        })
      },
      toggleTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;      
      },

      querySelections (v) {
        this.loading = true
        setTimeout(() => {
            this.items = this.regiones.filter(e => {
              return (e.nombre || '').toLowerCase().indexOf((v.nombre || '').toLowerCase()) > -1
            })
            this.loading = false
          }, 500)
        },

        ordenarLista(lista){
          lista = lista.sort(function(a,b){
            return a.id-b.id;
          })
          

        },

       

      cambiarRegion(region){
        this.create_data_region(region)
        this.comunaDetail = {id: '0',nombre: 'Comunas'}
        this.comuna = 0
        this.region = region
      },

      cambiarComuna(comuna){
        this.comuna = comuna
      }
    },

    


  }
</script>
